import Keycloak from 'keycloak-js'
import config from './config'

const kcConfig = {}
kcConfig.url = config.KC_URL
kcConfig.realm = config.KC_REALM
kcConfig.clientId = config.KC_CLIENT_ID

const keycloak = new Keycloak(kcConfig)

keycloak.hasClientRole = function ({ roleName, clientId = kcConfig.clientId }) {
  const clientAccess =
    this.tokenParsed && this.tokenParsed.resource_access[String(clientId)]
  return !!(clientAccess && clientAccess.roles.includes(roleName))
}

keycloak.getUserFullName = () => {
  if (!(keycloak && keycloak.tokenParsed)) {
    return "Unknown User";
  }
  return `${keycloak.tokenParsed.given_name} ${keycloak.tokenParsed.family_name}`
}

export default keycloak
